import(/* webpackMode: "eager", webpackExports: ["VirtualConsoleLog"] */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/app/components/VirtualConsoleLog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/app/error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/packages/azure/ApplicationInsights.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DictionaryProvider"] */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/packages/dictionary/DictionaryContext.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/radio-ai-frontend/radio-ai-frontend/packages/ui/ui.css");
