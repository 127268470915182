'use client';

import { useEffect } from 'react';
import packageJson from '../../package.json';

export const VirtualConsoleLog = () => {
  useEffect(() => {
    const { version } = packageJson;
    const website = `https://genaudio.io`;

    console.log(
      `%c 🚀 Welcome to GenAudio!%c \n\n Version: ${version}\n\n Visit us at: ${website}\n\n`,
      'color: white; background: #4CAF50; font-size: 16px; padding: 4px; border-radius: 5px;',
      'color: #4CAF50; font-size: 14px; font-weight: bold;'
    );
  }, []);

  return null;
};
