const isLocal = process.env.NEXT_PUBLIC_ENV === 'local';
const isStaging = process.env.NEXT_PUBLIC_ENV === 'staging';
const isDev = process.env.NEXT_PUBLIC_ENV === 'development';
const DEBUG = isLocal || isStaging || isDev;

export function Logger(type: 'info' | 'error', context: string, message: string, data: any = '') {
  // if (!DEBUG) return;

  const styles = {
    info: 'color: white; background: #0096FF; font-size: 12px; padding: 4px; border-radius: 4px;',
    error: 'color: white; background: #F44336; font-size: 12px; padding: 4px; border-radius: 4px;',
    default: 'color: white; background: #2196F3; font-size: 12px; padding: 4px; border-radius: 4px;',
  };

  switch (type) {
    case 'info':
      console.info(`%c${context} => ${message}`, styles.info, data);
      break;

    case 'error':
      console.error(`%c${context} => ${message}`, styles.error, data);
      break;

    default:
      console.log(`%c${context} => ${message}`, styles.default, data);
      break;
  }
}
