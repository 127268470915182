'use client';

import { applicationInsights } from '@/packages/azure/ApplicationInsights';
import { Button } from '@/packages/ui/Button';
import { Logger } from '@/packages/utils/log';
import { useEffect } from 'react';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Logger('error', 'global', 'exception', { error });
    if (applicationInsights) {
      applicationInsights.trackException({ error, severityLevel: 3 }, { error });
    }
  }, [error]);

  return (
    <main className="grid h-svh items-center justify-center">
      <div className="grid gap-8">
        <h2 className="text-center text-22 font-bold">Something went wrong!</h2>
        <Button label="Go to homepage" variant="primary" href="/" />
      </div>
    </main>
  );
}
